.plugin {
  margin: var(--margin-m) auto;
}

.plugin--button {
  text-align: center;
}

.plugin--wrap {
  padding: var(--margin-m) 0;
  background-color: var(--bg-featured);
  color: var(--color-featured);

  h1,
  h2,
  h3 {
    &:first-child {
      margin-top: 0;
    }
  }
}

main > .plugin--wrap:last-child {
  margin-bottom: 0;
}
