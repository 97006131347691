/* config */
.card,
.filter-results {
  --box-shadow: 3px;
}

/* styling */

.card {
  position: relative;
  display: block;
  background-color: var(--white);
  border: 1px solid var(--color-purple);
  border-radius: var(--spacing);
  box-shadow: var(--box-shadow) var(--box-shadow) 0px 0px var(--color-purple);

  padding: var(--p-card-hor) var(--p-card-ver);

  margin-bottom: 24px;
}

.card--organization {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  color: var(--color-purple-dark);

  .header {
    font-size: var(--font-size-xxsmall);
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--margin-s);
  }

  /* h2 {
    color: inherit;
  } */

  p {
    font-weight: 400;
    font-size: var(--font-size-xsmall);
    /* color: inherit; */
    /* margin-bottom: var(--spacing); */
  }

  .centered {
    align-self: center;
  }

  .btn--shadowed {
    margin: 0;
  }
}

/* .card--organization--active {
  color: var(--white);
  background-color: var(--color-purple);

  .btn--filter {
    border-color: var(--white);
    background-color: var(--white);
    color: var(--color-purple);
  }

  .btn--shadowed {
    box-shadow: var(--box-shadow) var(--box-shadow) 0px var(--box-shadow)
      var(--color-purple-dark);
  }
} */

.card--form {
  padding: var(--p-card-form-hor) var(--p-card-form-ver);
  background-color: var(--color-grey-light);
}

.organization__activity,
.organization__issues,
.organization__network,
.organization__experience {
  font-size: var(--font-size-small);
  font-weight: 400;

  span {
    display: inline-block;
  }
  a {
    text-decoration: none;
    color: var(--color-purple);
    font-weight: 700;
    font-size: var(--font-size-xxsmall);
  }
}

.card--organization span {
  margin-right: var(--margin-xs);
}
