:root {
  --c1: 5.5rem;
  --c2: 12.5rem;
  --c3: 19.5rem;
  --c4: 26.5rem;
  --c5: 33.5rem;
  --c6: 40.5rem;
  --c7: 47.5rem;
  --c8: 54.5rem;
  --c9: 61.5rem;
  --c10: 68.5rem;
  --c11: 75.5rem;
  --c12: 82.5rem;
  --c-outer: 85.51rem;

  --w: var(--c12);
  /* --w-content: var(--c12); */
  --w-max: 94.5rem;

  --p-card-hor: 1.3rem;
  --p-card-ver: 1rem;
  --p-card-form-hor: 1.75rem;
  --p-card-form-ver: 1.6rem;

  --p: 1rem;
  --gap: 0.5rem;
  --grid-gap: 2.5rem;

  --spacing-xs: 0.5rem;
  --spacing-s: 0.625rem;
  --spacing: 1.25rem;

  --margin-xs: 0.3rem;
  --margin-s: 1rem;
  --margin: 3rem;
  --margin-m: 6rem;
  --margin-l: 10rem;
  --margin-xl: 12.5rem;

  --icon-size: 22px;
  --icon-color: var(--white);

  --errors-fg: red;

  --interact-size: max(12px, 1.625rem);
  --screening-animation-time: 0.5s;

  --z-header: 20;
  --z-main-gradient: 18;
  --z-footer: 19;
  --z-gallery-gradient: 10;
  --z-gallery-control: 12;
  --z-modal: 50;
  --z-mobile-menu: 90;
  --z-menu-cta: 93;

  --filter-button-height: 28px;

  --form-field-height: 2.4rem;

  --mobile-bg-offset-search: 4rem;

  @media (--media-md) {
    --p-card-hor: 2.6rem;
    --p-card-ver: 2rem;
    --p-card-form-hor: 3.5rem;
    --p-card-form-ver: 3.2rem;
  }
}
