[data-directory-overview] {
  position: relative;
  z-index: 2;
  &::before {
    display: block;
    content: "";
    position: absolute;
    width: 100vw;
    height: 178px;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    background-color: var(--color-purple-light);
    z-index: -999;
    @media (--media-sm-only) {
      top: var(--mobile-bg-offset-search);
      height: 100%;
    }
  }
}

.filter-card {
  position: relative;
  .btn--close {
    position: absolute;
    right: var(--p-card-hor);
    top: var(--p-card-hor);
    cursor: pointer;
    @media (--media-md) {
      display: none;
    }
  }
}

.filter__utilities {
  margin-top: var(--margin-s);
  .btn--clean {
    padding-left: 0;
  }
}

.filter__horizontal-rule {
  margin-top: var(--margin-s);
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid var(--color-purple-dark);
  opacity: var(--alpha);
}

.filter__heading {
  font-weight: 700;
  font-size: 1rem;
  /* color: var(--color-purple-dark);
  margin-bottom: var(--margin-s); */
}

.filter__button-list {
  li {
    display: inline-block;
  }
  &:not(button.active) {
    font-size: 0;
  }
}

.mobile-search-button {
  text-align: center;
}

.filter-results__header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 1.75rem 0;
  padding-top: var(--spacing-s);

  h2 {
    color: var(--color-purple-dark);
  }
  h2,
  button {
    margin-bottom: 0;
    display: inline-block;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  @media (--media-sm-only) {
    height: var(--mobile-bg-offset-search);
    flex-direction: column;
    align-items: center;
    margin: var(--spacing-s);
    h2 {
      font-size: 1rem;
    }
    button {
      margin-right: 0;
    }
  }
}

.filter__heading-container {
  display: flex;
  justify-content: space-between;
}

.filter__collapse-button {
  color: var(--color-purple-dark);
  font-size: var(--font-size-xxsmall);
  font-weight: 700;
  display: flex;
  align-items: flex-start;
  background-color: inherit;
  border: none;
  gap: var(--spacing-s);
}

.collapsed .btn--filter:not(.active) {
  display: none;
}

.filter__warning-message {
  font-size: var(--font-size-xxsmall);
  color: var(--color-purple);
  cursor: pointer;
}

.filter__collapse-container:has(.active) + .filter__warning-message,
.filter__collapse-container:not(.collapsed) + .filter__warning-message {
  display: none;
}

.collapse-button__arrow-down,
.collapse-button__arrow-up {
  transition: all 0.3s ease-in-out;
}

.collapse-button__arrow-up {
  transform: rotate(-180deg);
}

.filter__input-keyword {
  width: 100%;
  -webkit-appearance: none; /* iOS fix */
  color: var(--color-purple);
  background-color: var(--white);
  height: var(--form-field-height);
  border-radius: calc(var(--form-field-height) / 2);
  border: 1px solid var(--color-purple);

  font-size: var(--font-size-xxsmall);
  font-weight: 700;
  padding: 0 calc(var(--form-field-height) / 2);

  &:focus {
    box-shadow: 3px 2px 11px 0 var(--black);
    outline: none;
  }
}
