.form {
  font-size: var(--interact-size);

  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    transition: opacity 0.1s ease-out;
  }

  fieldset[disabled] {
    opacity: 0.7;
  }

  .field {
    margin-bottom: var(--grid-gutter);
  }

  small {
    display: block;
  }

  label {
    display: block;
    font-weight: 400;
    font-size: var(--font-size-xsmall);
    margin-bottom: var(--spacing-s);
  }

  .submission-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .widget--checkboxselectmultiple + .widget--textinput {
    margin-top: calc(-1 * var(--grid-gutter));
    position: relative;

    label {
      cursor: pointer;
      color: var(--color-purple);
      display: none;
    }

    input {
      width: 20%;
      margin-top: 5px;
    }
    .btn--close {
      display: none;
    }

    label[class~="hidden"] + input {
      display: block;
      position: relative;
    }

    label[class~="hidden"] ~ .btn--close {
      display: block;
      position: absolute;
      left: 71.5%;
      top: 7px;
    }
  }

  /* .field > label {
      margin-bottom: calc(0.25 * var(--grid-gutter));
    } */

  .field > label.required {
    &::after {
      content: "*";
    }
  }

  .field > label.error {
    color: var(--errors-fg);
  }

  input[type="file"],
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="url"],
  input[type="tel"],
  select,
  textarea {
    width: 100%;
    /* max-width: 670px; */
    -webkit-appearance: none; /* iOS fix */
    appearance: none;
    color: var(--color-purple);
    background-color: var(--white);
    height: var(--form-field-height);
    border-radius: calc(var(--form-field-height) / 2);
    border: 1px solid var(--color-purple);

    font-size: var(--font-size-xxsmall);
    font-weight: 700;
    padding: 0 calc(var(--form-field-height) / 2);

    &:focus {
      box-shadow: 3px 2px 11px 0 var(--black);
      outline: none;
    }
  }

  input,
  select {
    line-height: var(--form-field-height);
  }

  textarea {
    resize: none;
  }

  input[type="file"] {
    cursor: pointer;

    &::file-selector-button {
      cursor: pointer;
      color: var(--color-purple);
      background-color: var(--white);
      border-radius: calc(var(--form-field-height) / 2);
      border: 1px solid var(--color-purple);
      font-size: var(--font-size-xxsmall);
      font-weight: 900;
      &:hover {
        background-color: var(--button-hover);
      }
    }
  }

  textarea {
    height: auto;
    max-height: 8rem;
    padding: calc(var(--form-field-height) / 4)
      calc(var(--form-field-height) / 2);
  }

  .widget--textarea .selected {
    z-index: 1;
    position: relative;
  }

  input[type="number"],
  select {
    /* width: auto; */
    min-width: 10rem;
  }

  .widget--textinput,
  .widget--numberinput {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  .widget--radioselect {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  .widget--radioselect label {
    margin-left: 0.5rem;
  }

  .widget--selectdatewidget {
    display: flex;
    flex-flow: row wrap;
    label {
      flex: 1 0 100%;
    }
    select {
      flex: 0 1 min-content;
      &:not(:last-of-type) {
        margin-right: var(--spacing-s);
      }
    }
  }

  .widget--select select {
    background: transparent;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTknIGhlaWdodD0nMTEnIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZmlsbC1ydWxlPSdldmVub2RkJyBjbGlwLXJ1bGU9J2V2ZW5vZGQnIGQ9J00yLjUzLjAxMmMuNDgtLjAwNC45NDMuMTggMS4yODkuNTE0bDYuMDM3IDYuMDM3TDE1Ljg5My41MjdhMS44MzMgMS44MzMgMCAwIDEgMi41OTIgMi41OTNsLTcuMzMzIDcuMzMzYTEuODMzIDEuODMzIDAgMCAxLTIuNTkyIDBMMS4yMjYgMy4xMTlBMS44MzMgMS44MzMgMCAwIDEgMi41My4wMTJaJyBmaWxsPScjNGQyYWNkJy8+PC9zdmc+");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 0.75rem);
    background-position-y: 50%;
  }

  .widget--selectmultiple {
    height: 100%;

    div:not([class]) {
      height: 100%;
      max-height: 8rem;
      background-color: var(--white);
      border-radius: calc(var(--form-field-height) / 2);
      border: 1px solid var(--color-purple);
      padding: var(--spacing-s) calc(var(--spacing-s) * 2) var(--spacing-s)
        calc(var(--form-field-height) / 2);
    }

    select {
      height: 100%;
      border: none;
      border-radius: 0;
      padding: 0;
      margin: 0;
    }
    option {
      font-size: var(--font-size-xxsmall);
      font-weight: 700;
    }
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none; /* iOS fix */
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    margin: 0;
    font: inherit;
  }

  #id_default_language_portrait,
  #id_portrait_language,
  #id_contact_quote_language,
  #id_default_language_contact_quote {
    display: flex;

    & label {
      cursor: pointer;
      position: relative;
      padding: 0.1rem 1rem;
      color: var(--color-purple);
      font-weight: 700;
      border: 1px solid var(--color-purple);
      border-radius: 5rem;

      &:hover {
        background-color: var(--button-hover);
      }

      &.selected {
        background-color: var(--color-purple);
        color: var(--white);
      }
    }
  }

  #id_portrait_language,
  #id_contact_quote_language {
    display: flex;
    gap: 0.3em;
    margin-bottom: -1.7rem;
    margin-left: -0.1rem;
    z-index: 0;
    position: relative;
    & label {
      cursor: pointer;
      position: relative;
      padding: 0.1rem 1rem;
      color: var(--color-purple);
      background-color: var(--white);
      font-weight: 700;
      border: 1px solid var(--color-purple);
      border-radius: 5rem;

      &.selected {
        background-color: var(--white);
        color: var(--color-purple);
        border-radius: 100px;

        &:before {
          content: "";
          display: block;
          width: 125%;
          height: 300%;
          background-color: var(--color-purple);
          position: absolute;
          z-index: -1;
          left: -14%;
          top: -25%;
          border-radius: 15px;
        }

        &:after {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          border-bottom-left-radius: 50%;
          background-color: var(--color-grey-light);
          -webkit-filter: drop-shadow(-2px 1px 0 var(--color-purple));
          filter: drop-shadow(-2px 1px 0 var(--color-purple));
          position: absolute;
          z-index: -1;
          left: 111%;
          bottom: -35%;
        }
      }
    }
  }

  input[name="portrait_language"],
  input[name="default_language_portrait"],
  input[name="default_language_contact_quote"],
  input[name="contact_quote_language"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
  }

  .selected input[name="portrait_language"],
  .selected input[name="contact_quote_language"] {
    top: 80%;
    left: -43%;

    &::before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-bottom-right-radius: 50%;
      background-color: var(--color-grey-light);
      -webkit-filter: drop-shadow(2px 1px 0 var(--color-purple));
      filter: drop-shadow(2px 1px 0 var(--color-purple));
      position: absolute;
      z-index: -1;
    }
  }

  #id_portrait_language_0::before,
  #id_contact_quote_language_0:before {
    display: none;
  }

  /* the width is a bit small so the left is increased*/
  input[name="portrait_language"][value="it"],
  input[name="contact_quote_language"][value="it"] {
    left: -47%;
  }
  /* the width is a bit small so the left is increased*/
  input[name="portrait_language"][value="fr"],
  input[name="contact_quote_language"][value="fr"],
  input[name="portrait_language"][value="de"],
  input[name="contact_quote_language"][value="de"] {
    left: -44%;
  }

  #id_default_language_portrait,
  #id_default_language_contact_quote {
    padding-left: 1em;
    column-gap: 1em;
    @media (--media-sm-only) {
      flex-wrap: wrap;
    }

    & label::before {
      position: absolute;
      content: "";
      width: 0.6em;
      height: 0.6em;
      left: -1.2em;
      top: 50%;
      border-radius: 50%;
      transform: translateY(-50%);
      outline: 1px solid var(--color-purple);
      outline-offset: 2px;
    }

    label.selected::before {
      background-color: var(--color-purple);
    }
  }

  .widget--checkboxselectmultiple > div {
    display: flex;
    flex-wrap: wrap;

    label {
      display: inline-flex;
      align-items: center;

      font-size: var(--font-size-xxsmall);
      font-weight: 700;

      color: var(--color-purple);
      background-color: var(--white);

      margin: 0 10px 10px 0;
      height: var(--filter-button-height);

      border: 1px solid var(--button-color);
      border-radius: calc(var(--filter-button-height) / 2);
      padding: 0 18px;

      transition: background-color 0.3s ease-out;
      transition: color 0.2s ease-out;

      cursor: pointer;

      &:hover {
        background-color: var(--button-hover);
      }

      .icon-xmark {
        display: none;
      }
    }

    input[type="checkbox"] {
      &:checked + label {
        color: var(--white);
        background-color: var(--color-purple);
      }

      &:checked + label .icon-xmark {
        display: block;
      }

      &:focus + label {
      }

      &:disabled + label {
      }
    }
  }

  .widget--clearablefileinputwithpreviewandppoi label {
    width: 100%;
    /* max-width: 534px; */
    -webkit-appearance: none; /* iOS fix */
    color: var(--color-purple);
    background-color: var(--white);
    height: var(--form-field-height);
    border-radius: calc(var(--form-field-height) / 2);
    border: 1px solid var(--color-purple);

    font-size: var(--font-size-xxsmall);
    font-weight: 700;
    padding: 0 calc(var(--form-field-height) / 2);

    display: flex;
    align-items: center;
    justify-content: flex-end;

    cursor: pointer;

    &:focus {
      box-shadow: 3px 2px 11px 0 var(--black);
      outline: none;
    }
  }

  .label-with-help {
    display: flex;
  }

  .help {
    font-size: var(--font-size-xsmall);
    margin-bottom: var(--spacing-s);
  }

  .info {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    gap: var(--spacing-s);
    font-size: 0.8rem;
    color: var(--color-purple);
    margin-top: calc(-1 * var(--spacing-s));

    .info-icon svg {
      width: 30px;
      height: 30px;
    }
  }

  .errors,
  .errorlist {
    color: var(--errors-fg);

    li {
      font-size: var(--font-size-xsmall);
    }

    h3 {
      color: inherit; /* Override element styling :-( */
    }
  }

  .error input {
    border-color: red;
  }

  #id_states option {
    padding: 0.3rem;
  }

  @media (--media-md) {
    .field-50-50 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 var(--grid-gutter);
    }
    .field-25-75 {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 0 var(--grid-gutter);
    }
    .field-75-25 {
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: 0 var(--grid-gutter);
    }
    .field-70-30 {
      display: grid;
      grid-template-columns: 7fr 3fr;
      gap: 0 var(--grid-gutter);
    }
  }

  .message {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--gray);
  }
}

.form__submit {
  text-align: right;
}
