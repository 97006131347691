main {
  flex: 1 1 100%;
}

.hero-container + .container {
  margin-top: var(--margin-m);
}

main > .container:last-child {
  margin-bottom: var(--margin-m);
}

.container > .container {
  --p: 0;
}

.leadin {
  display: flex;
  gap: 4rem;

  figure {
    @media (--media-sm-only) {
      display: none;
    }
  }
}
