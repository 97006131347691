.f3cc {
  --f3cc-background-color: var(--color-purple);
  --f3cc-foreground-color: white;
  --f3cc-button-foreground: var(--color-purple);
  --f3cc-button-background: white;
  --f3cc-accept-background: white;
}

.f3cc .f3cc-button {
  font-weight: 700;
  border-radius: 0.625rem;
}

.f3cc .f3cc-button.modify {
  background-color: var(--color-purple);
  color: #fff;
  padding: 0.5rem 0.75rem;
}
