.icon {
  width: var(--icon-size);
  height: var(--icon-size);
  fill: var(--icon-color);
}

.button-icon {
  --icon-size: 1em;
  transform: translateX(0.33em);
}

.icon-magnifying-glass {
  --icon-size: 16px;
  margin-right: var(--spacing-s);
}

.icon-xmark {
  --icon-size: 10px;
  margin-left: var(--spacing-s);
}
