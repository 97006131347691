.sr-only {
  width: 0px;
  height: 0px;
  position: absolute;
  overflow: hidden;
  &.skip-link:focus-visible {
    height: auto;
    width: auto;
  }
}

.hidden,
.hide {
  display: none !important;
}

.flex {
  display: flex;
  gap: var(--grid-gap);
}

.embedded-video__container > div {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.6615620214%;
}

.embedded-video__container > div iframe {
  position: absolute;
  inset: 0;
}

@media (--media-sm-only) {
  .hero-image {
    padding-left: 0;
    padding-right: 0;
  }
}
