/* TODO: we could use variable fonts here */
:root {
  --font-size-small: 0.9375rem; /*15px if 1rem = 16px*/
  --font-size-xsmall: 0.875rem; /*14px*/
  --font-size-xxsmall: 0.75rem; /*12px*/
}

/* work-sans-regular - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src:
    local("WorkSans-Regular"),
    url(../fonts/WorkSans-Regular.woff2) format("woff2");
  font-display: swap;
}
/* work-sans-700 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  src:
    local("WorkSans-SemiBold"),
    url(../fonts/WorkSans-SemiBold.woff2) format("woff2");
  font-display: swap;
}
/* work-sans-800 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 800;
  src:
    local("WorkSans-Bold"),
    url(../fonts/WorkSans-Bold.woff2) format("woff2");
  font-display: swap;
}

html {
  /* XXX Do not do this: Accessability! */
  /* font-size: clamp(10px, 0.75vw, 16px); */
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Work Sans", Helvetica, Arial, sans-serif;
  line-height: 1.4;

  font-size: 1.625rem;
}

a[class]:not(.button) {
  text-decoration: none;
  /* color: inherit; */
}

h1,
h2,
h3 {
  font-weight: 800;
  color: var(--color-purple-dark);
  word-break: normal;
  hyphens: auto;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: var(--spacing);
}

h2 {
  font-size: 1.5rem;
  margin-bottom: var(--spacing-xs);
}

h3 {
  font-size: 1rem;
  margin-bottom: var(--spacing-xs);
}

ul,
ol {
  font-size: 1rem;
}

li {
  font-size: inherit;
}

p,
.button,
figure,
ul,
ol,
table {
  margin-bottom: var(--spacing);
}

.prose {
  width: 100%;

  p {
    font-size: 1.125rem;
  }

  a:not([class]) {
    color: var(--link-color);
  }
}

figcaption {
  /* text-align: center; */
  /* margin: 0.2rem 0; */
  margin-bottom: var(--margin);
}

figcaption,
small {
  font-size: 0.8rem;
  /* color: var(--gray); */
}

.lead {
  font-size: 1.1rem;
  /* color: var(--gray); */
  font-weight: bold;
}
