.organization-detail {
  h1 {
    font-size: 1.5rem;
    color: var(--color-purple-dark);
  }

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: var(--grid-gap);

  grid-template-areas:
    "name contact"
    "portrait person"
    "activity issues"
    "network experience";

  .organization__name {
    grid-area: name;
  }
  .organization__contact {
    grid-area: contact;
  }
  .organization__portrait {
    grid-area: portrait;
  }
  .organization__person {
    grid-area: person;
  }
  .organization__activity {
    grid-area: activity;
  }
  .organization__issues {
    grid-area: issues;
  }
  .organization__network {
    grid-area: network;
  }
  .organization__experience {
    grid-area: experience;
  }

  @media (--media-sm-only) {
    grid-template-columns: 1fr;
    gap: calc(0.5 * var(--grid-gap));
    grid-template-areas:
      "name"
      "portrait"
      "contact"
      "person"
      "activity"
      "issues"
      "network"
      "experience";
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .cp {
    display: flex;
    background-color: var(--color-purple-light);
  }
  .card {
    padding: 0;
  }
}

.organization__visual {
  padding: var(--p-card-ver) var(--p-card-ver) 0;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 270px;
  @media (--media-sm-only) {
    height: 194px;
  }

  &::before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-image: var(--visual-url);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: var(--spacing) var(--spacing) 0 0;
  }
}

.organization__visual--spacer {
  height: 270px;
  @media (--media-sm-only) {
    height: 194px;
  }
}

.organization__name p {
  font-size: var(--font-size-xxsmall);
  opacity: 0.7;
}

.response-time-container {
  font-size: var(--font-size-xxsmall);
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  margin-top: var(--margin-s);
}

.organization__portrait {
  p {
    font-size: var(--font-size-small);
    font-weight: 400;
    color: var(--color-purple-dark);
  }
}

.organization__experience {
  margin-top: 15px;
}

.organization__contact {
  p {
    font-size: var(--font-size-small);
    font-weight: 400;
    color: var(--color-purple-dark);
  }
  label {
    display: none;
  }
  h3 {
    margin-top: var(--grid-gutter);
  }
  input {
    width: 50% !important;
  }

  .form {
    .field {
      margin-bottom: 0;
    }
  }
}

.organization__socials {
  display: flex;
  flex-wrap: wrap;

  .btn:last-child {
    margin-right: 0;
  }

  @media (--media-md) {
    justify-content: left;
  }
}

.organization__person .card {
  display: flex;
  margin-bottom: 0;
}

.person__image {
  max-height: 100%;
  max-width: 30%;
  object-fit: cover;
  border-radius: inherit;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  font-size: var(--font-size-xxsmall);
}

.person__details {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-s);
  font-size: var(--font-size-xsmall);
  font-weight: 800;
  line-height: 1.1;
  color: var(--color-purple-dark);
  gap: var(--spacing-s);

  .name,
  .function {
    font-size: var(--font-size-xxsmall);
  }

  .function {
    display: block;
    font-weight: 400;
  }
}
