.button,
.btn {
  -webkit-appearance: none; /* iOS fix */

  display: inline-flex;
  align-items: center;
  gap: var(--gap);
  /* border-radius: 0.5em; */
  padding: 0.5rem 1rem;
  cursor: pointer;

  font-size: var(--interact-size);

  text-decoration: none;
  color: var(--button-color);
  background: var(--button-bg);
  border: 1px solid var(--button-color);
  margin: 0 10px 10px 0;

  &:hover {
    background-color: var(--button-hover);
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--shadowed {
    --box-shadow: 3px;
    font-size: 0.87rem;
    font-weight: 700;
    border-radius: 0.625rem;
    box-shadow: var(--box-shadow) var(--box-shadow) 0px 0px var(--color-purple);
    padding: 5px 12px;
    /* margin-bottom: 1rem; */
  }

  &--filter {
    color: var(--color-purple);
    background-color: var(--white);
    height: var(--filter-button-height);
    border-radius: calc(var(--filter-button-height) / 2);
    padding: 0 18px;

    font-size: var(--font-size-xxsmall);
    font-weight: 700;

    transition: background-color 0.3s ease-out;
    transition: color 0.2s ease-out;

    /* try without glass */
    svg {
      display: none;
    }

    .icon-xmark {
      visibility: hidden;
    }

    &.active,
    &:focus-visible {
      color: var(--white);
      background-color: var(--color-purple);
    }
    &.active {
      .icon-xmark {
        visibility: visible;
      }
    }
  }

  &--close {
    border: 0;
    width: 24px;
    height: 24px;
    background: transparent;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iaWNvbi1jbG9zZSIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xOSA2LjQxIDE3LjU5IDUgMTIgMTAuNTkgNi40MSA1IDUgNi40MSAxMC41OSAxMiA1IDE3LjU5IDYuNDEgMTkgMTIgMTMuNDEgMTcuNTkgMTkgMTkgMTcuNTkgMTMuNDEgMTJ6IiBmaWxsPSIjNGQyYWNkIi8+PC9zdmc+");
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
  }

  /* &--inverted {
    color: var(--button-bg);
    background: var(--button-color);
    border-color: var(--button-color);

    .icon {
      fill: var(--button-bg);
    }

    &:hover,
    &:focus-visible {
      color: var(--button-color);
      background: var(--button-bg);
      border-color: var(--button-color);

      .icon {
        fill: var(--button-color);
      }
    }
  }

  &--special {
    --button-color: var(--white);
    --button-bg: var(--black);
    border-color: var(--black);
    --icon-color: var(--white);
    / * border-radius: 2em; * /
    padding: 1.2em 2em;
  }
*/
  &--clean {
    background: none;
    border: none;
    font-size: var(--font-size-xsmall);
    font-weight: 800;

    &:hover,
    &:focus-visible {
      background: none;
      border: none;
    }
  }

  &--menu-toggle {
    --icon-size: 1.645em;
    color: var(--nav-color);
    .icon {
      fill: var(--nav-color);
    }
    padding: 0;
    margin: 0;
  }

  &--arrow,
  &--download,
  &--arrow-back {
    --icon-size: 1.2em;
  }

  &--arrow,
  &--download {
    .icon {
      transform: translateX(0.25em);
      fill: var(--color-purple);
    }
  }

  &--download {
    font-size: var(--font-size-xsmall);
  }

  &--arrow-back {
    .icon {
      transform: translateX(-0.25em);
    }
  }
}

/* .filter .btn--filter {
  background-color: var(--button-bg-dark);
  color: var(--white);
} */

.contact--phone {
}

.contact--email {
}

.contact--linkedin {
}
