:root {
  --color-purple: #4d2acd;
  --color-purple-dark: #22185b;
  --color-purple-light: #f4f0fe;
  --color-grey-light: #fbfbfb;

  --white: #ffffff;
  --black: #08080d;
  --gray: #6f7378;

  --alpha: 0.3;

  --button-color: var(--color-purple);
  --button-bg: var(--white);
  --button-bg-dark: var(--color-purple);
  --button-hover: rgba(77, 42, 205, 0.3);

  --nav-color: var(--color-purple-dark);

  --link-color: var(--color-purple);

  --errors-fg: var(--red);

  --header-bg: var(--white);
  --header-color: var(--black);

  --footer-bg: var(--white);
  --footer-color: var(--color-purple-dark);
}
