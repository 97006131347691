footer {
  background: var(--footer-bg);
  color: var(--footer-color);

  p {
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: var(--spacing);
    @media (--media-sm-only) {
      margin-bottom: var(--spacing-xs);
    }
  }

  a {
    color: var(--color-purple-dark);
  }
}

.footer-container {
  border-top: 1px solid var(--footer-color);
  padding-top: var(--margin-s);
  margin-bottom: var(--margin);

  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing);

  align-items: center;
}

.contact-container {
  flex: 1 1 var(--c4);

  /* --icon-size: 2rem; */

  display: flex;
  flex-direction: column;

  .icon {
    width: calc(var(--icon-size) * 1.2);
    height: calc(var(--icon-size) * 1.2);
  }
  @media (--media-lg) {
    flex-direction: row;
    align-items: center;
    gap: var(--spacing);
  }
}

.contact__header {
  font-size: var(--font-size-xsmall);
  @media (--media-md) {
    margin-bottom: 0;
  }
}

.contact-link {
  color: inherit;
  display: inline-flex;
  align-items: center;
  gap: 0.5em;
  font-weight: 800;
  line-height: 1;
  font-size: var(--font-size-xsmall);
}

.footer__navlink-list {
  @media (--media-lg) {
    display: flex;
    gap: var(--spacing);
  }

  li {
    line-height: 1.5;

    @media (--media-md) {
      margin-bottom: var(--spacing-s);
    }
  }

  a {
    font-size: 14px;
  }
}
