:root {
  --header-height: 78px;
  --header-height-collapsed: 46px;
  --logo-height: 50px;
  --logo-height-collaped: 30px;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: var(--z-header);
  display: flex;

  color: var(--header-color);
  background-color: var(--header-bg);
  transition: background-color 0.3s ease-in-out;

  box-shadow: 0 3px 6px var(--color-purple-light);
}

.header-container,
.mobile-menu .toggle-container {
  display: flex;
  align-self: center;
  height: var(--header-height);
  align-items: center;

  transition: height 0.3s ease-out;

  .scrolled & {
    height: var(--header-height-collapsed);
  }
}

.header-container {
  width: 100%;
  justify-content: space-between;
  gap: var(--spacing);
  align-items: center;
  margin: auto;

  padding-left: calc(0.5 * var(--grid-gutter));
  padding-right: calc(0.5 * var(--grid-gutter));
}
.toggle-container {
  @media (--media-md) {
    display: none;
  }
  justify-content: right;
}

header .logo-container {
  height: var(--logo-height);
  transition: height 0.3s ease-out;
  svg {
    height: var(--logo-height);
    transition: all 0.3s ease-out;
  }
  .scrolled & {
    height: var(--logo-height-collaped);
    & #Mark {
      opacity: 0;
    }
    svg {
      height: var(--logo-height-collaped);
      transform: scale(0.6);
    }
  }
}

.logo-container,
.main-menu,
.language-nav {
  flex: 1 0 auto;
}

.logo-container {
  flex-basis: 130px;
}

.menu-is-open {
  overflow: hidden;
}

.mobile-menu {
  display: none;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 100vh;
  padding-left: calc(0.5 * var(--grid-gutter));
  padding-right: calc(0.5 * var(--grid-gutter));

  background-color: var(--header-bg);
  z-index: var(--z-mobile-menu);

  overflow-y: auto;

  .nav-toggle {
    align-self: center;
  }
}

.cta-container {
  flex: 0 1 auto;
  margin-left: auto;
  .cta-nav {
    display: flex;
    gap: var(--spacing);
  }
}

.menu-is-open .mobile-menu {
  display: block;
}

.nav-toggle {
  line-height: 0;
  @media (--media-md) {
    display: none;
  }
}

.main-menu,
.main-menu + .language-nav {
  @media (--media-sm-only) {
    display: none;
  }
}

.language-nav {
  .nav-list {
    justify-content: right;
  }
  .nav-link {
    font-size: 1rem;
    text-transform: uppercase;
  }
  .active {
    color: var(--color-purple);
  }
  @media (--media-sm-only) {
    padding-right: var(--spacing);
  }
}

.nav-list {
  display: flex;
  gap: var(--spacing);
  align-items: flex-start;
  flex-wrap: wrap;
  @media screen and (max-width: 800px) {
    gap: 40px;
  }
}

.nav-link {
  color: var(--nav-color);
  font-size: 1.25rem;
  font-weight: 400;

  &.active {
    font-weight: 800;
  }
}

.mobile-nav {
  font-size: 1.5rem;

  .nav-list,
  .nav-link {
    font-size: inherit;
  }

  .nav-list {
    flex-direction: column;
    gap: 3.5rem;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}
